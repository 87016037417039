import React from "react";
import { Layout } from "@layout";
import { Grid, Text, Box, Flex, Col, Svg, Calendar } from "@components";
import useLayout from "./hooks";

import icon from "@assets/logo.svg";

const LayoutAdmin = () => {
  const { username } = useLayout();
  return (
    <Layout>
      <Grid className="pv:2">
        <Flex justify="center" className="mt:2">
          <Svg icon={icon} width={120} height={120} xs="120:120" />
        </Flex>
        <Text align="center" fw={500} fs={34} className="mt:1 mb:2">
          Bienvenido {username}
        </Text>

        <Flex justify="center">
          <Col>
            <Flex justify="center">
              <Col md={6} className="ph:05">
                <Box primary>
                  <Text fs={18} fw={500} className="mb:1">
                    Completa tu perfil
                  </Text>
                  <small>
                    Sin miedo al exito Papuu!
                  </small>
                </Box>
									<Box className='mt:2'>
										<Calendar placeholder="Fecha de nacimiento" />
									</Box>
              </Col>
            </Flex>

          </Col>
        </Flex>
      </Grid>
    </Layout>
  );
};

export default LayoutAdmin;

import React, { useEffect, useContext } from "react";
import AdminContext from "@context";
import { navigate } from "gatsby";

import { LayoutAdmin } from "@admin";
import { LayoutAfiliado } from "@afiliado";
import { LayoutCliente } from "@cliente";

const IndexPage = () => {
  const {
    auth: { role, isAuthenticated },
  } = useContext(AdminContext);

	useEffect(() => {
		!isAuthenticated && navigate('/login')
	}, [])

  if (role === "superadmin") return <LayoutAdmin />;
  if (role === "afiliado") return <LayoutAfiliado />;
  if (role === "cliente") return <LayoutCliente />;
	return null
};

export default IndexPage;

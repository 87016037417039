import React from "react";
import { Layout } from "@layout";
import { Grid, Text, Box, Flex, Col, Svg, Button } from "@components";
import useLayout from "./hooks";

import icon from "@assets/logo.svg";
import { navigate } from "gatsby";

const LayoutAfiliado = () => {
  const { username, unique_code } = useLayout();
  return (
    <Layout>
      <Grid className="pv:2">
        <Flex justify="center" className="mt:2">
          <Svg icon={icon} width={120} height={120} xs="120:120" />
        </Flex>
        <Text align="center" fw={500} fs={34} className="mt:1 mb:2">
          Bienvenido {username}
        </Text>

        <Flex justify="center">
          <Col>
            <Flex justify="around">
              <Col md={6} className="ph:05">
                <Box primary>
                  <Text fs={18} fw={500} className="mb:1">
                    Completa tu perfil
                  </Text>
                  <small>
                    Antes de comenzar a compartir tu link de
										afiliación a todo el mundo, por favor actualiza
										los datos faltantes en la sección Mi Perfil.
                  </small>

									<Flex className="mt:2">
										<Button square ghost onClick={() => navigate('/afiliacion/perfil')}>Ir a mi perfil</Button>
									</Flex>
                </Box>
              </Col>
              <Col md={6} className="ph:05">
                <Box>
                  <Text fs={18} fw={500} className="mb:1">
                    Código de afiliación
                  </Text>
                  <small className="opacity:5">
                    Comparte este código de afiliación y recibe increibles
                    comisiones por cada venta que se realice mediante tu{" "}
                    <b>link unico de afiliación</b>.
                  </small>
                </Box>
              </Col>
              <Col md={6} className="ph:05">
                <Box>
                  <Text fs={18} fw={500} className="mb:1">
                    Programa de comisiones
                  </Text>
                  <small className="opacity:5">
                    Recuerda por el momento todas las comisiones unicamente se
                    basan en el modelo <b>PPS</b> (Pay per Sell) o pago por
                    venta
                  </small>

									<Flex className="mt:2">
										<Button square primary onClick={() => navigate('/afiliacion')}>Ver comisiones</Button>
									</Flex>
                </Box>
              </Col>

							<Flex justify="center">
								<Col autofit>
									<Box inline className="mt:2 p:1">
										<Text fs={14}>
											<span>
												https://digitalbooting.com
											</span>
											/<b>a</b>/<b className="color:link">{unique_code}</b>
										</Text>
									</Box>
								</Col>
							</Flex>
            </Flex>
          </Col>
        </Flex>
      </Grid>
    </Layout>
  );
};

export default LayoutAfiliado;

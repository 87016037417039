import React from "react";
import { Layout } from '@layout';
import { Grid, Text, Box, Flex, Col } from "@components";

const LayoutCliente = () => {
  return (
    <Layout>
      <Grid className="pv:2">
        <Text fw={700} fs={34}>
          Bienvenido Afiliado
        </Text>

        <Flex className="mt:1">
          <Col md={10}>
            <Box>
              <p>url code afiliation</p>
            </Box>
          </Col>
        </Flex>
      </Grid>
    </Layout>
  );
};

export default LayoutCliente;
